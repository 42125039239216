






























































































































import { Component, Vue } from 'vue-property-decorator'
import symbolService from '@/services/symbolService'
import ws, { SocketMsg } from '@/services/socket'
import { PriceMsgContent, StockPrice } from '@/services/data'
import { setPriceValues } from '@/services/func'
import SymbolName from '@/components/SymbolName.vue'

const defaultSettings = {
  interval: '',
  chartOnly: true,
  locale: 'en',
  colorTheme: 'light',
  gridLineColor: '#F0F3FA',
  trendLineColor: '#2196F3',
  fontColor: '#787B86',
  underLineColor: '#E3F2FD',
  isTransparent: false,
  autosize: true,
  barStyle: 1,
  hideTopToolbar: false,
  showDetails: false,
  withDateRanges: false
}
// https://www.tradingview.com/widget/symbol-overview/
// https://www.tradingview.com/widget/advanced-chart/
@Component({
  components: {
    SymbolName
  }
})
export default class Account extends Vue {
  url = ''
  symbolId: number
  symbolPrice: StockPrice = null
  chartType = 'Lightweight'
  intervals = Object.freeze([
    { key: '1D', value: '1D' },
    { key: '1M', value: '1M' },
    { key: '3M', value: '3M' },
    { key: '1Y', value: '12M' },
    { key: '5Y', value: '60M' },
    { key: 'All', value: 'All' }
  ])

  techIntervals = Object.freeze([
    { key: '1m', value: '1' },
    { key: '3m', value: '3' },
    { key: '5m', value: '5' },
    { key: '15m', value: '15' },
    { key: '30m', value: '30' },
    { key: '1h', value: '60' },
    { key: '2h', value: '120' },
    { key: '3h', value: '180' },
    { key: '4h', value: '240' },
    { key: '1d', value: 'D' },
    { key: '1w', value: 'W' }
  ])

  barStyles = Object.freeze([
    { key: 'Bars', value: 0 },
    { key: 'Candles', value: 1 },
    { key: 'Hello Candles', value: 9 },
    { key: 'Heikin Ashi', value: 8 },
    { key: 'Area', value: 3 },
    { key: 'Renko', value: 4 },
    { key: 'Line Break', value: 7 },
    { key: 'Kagi', value: 5 },
    { key: 'Point and Figure', value: 6 }
  ])

  chatSetting = Object.assign({}, defaultSettings)

  async mounted () {
    const symbolId: number = parseInt(this.$route.query.symbolId as string)
    ws.InitAll(this.msgCallBack)
    this.symbolId = symbolId
    ws.SendAll({ page: 'Chart', symbols: [this.symbolId] })
    this.setUrl()
    const res = await symbolService.getSymbolPriceAsync(symbolId)
    if (res.Result) {
      this.symbolPrice = res.Result
    }
  }

  destroyed (): void {
    ws.RemoveAll(this.msgCallBack)
  }

  chartTypeChanged () {
    this.setUrl()
  }

  setUrl () {
    if (window.location.host.startsWith('localhost') || window.location.host.startsWith('192.168')) {
      this.url = `http://localhost:5000/api/symbol/getChart?symbolId=${this.symbolId}&chartType=${this.chartType}&timestamp=${new Date().getTime()}`
    } else {
      this.url = `https://agalica.com/api/symbol/getChart?symbolId=${this.symbolId}&chartType=${this.chartType}&timestamp=${new Date().getTime()}`
    }
  }

  async msgCallBack (msg: SocketMsg) {
    if (msg.Action === 'resStk' && msg.MsgId === this.symbolId) {
      const content = msg.Content as PriceMsgContent
      setPriceValues(content, this.symbolPrice.Price)
    }
  }

  colorThemeChanged () {
    if (this.chatSetting.colorTheme === 'dark') {
      this.chatSetting.gridLineColor = '#2a2e39'
      this.chatSetting.trendLineColor = '#1976d2'
      this.chatSetting.fontColor = '#787b86'
      this.chatSetting.underLineColor = 'rgba(55, 166, 239, 0.15)'
    } else {
      this.chatSetting.gridLineColor = '#f0f3fa'
      this.chatSetting.trendLineColor = '#2196f3'
      this.chatSetting.fontColor = '#787b86'
      this.chatSetting.underLineColor = '#e3f2fd'
    }
  }

  resetSetting () {
    this.chatSetting = Object.assign({}, defaultSettings)
    localStorage.setItem('chat-settings', JSON.stringify(this.chatSetting))
    setTimeout(() => this.setUrl(), 100)
  }

  applySetting () {
    localStorage.setItem('chat-settings', JSON.stringify(this.chatSetting))
    setTimeout(() => this.setUrl(), 100)
  }
}
